import React from "react";
import classnames from "classnames";
import "./OrderListItem.scss";

const OrderListItem = props => {
  const OrderListItemClass = classnames("order-list__item", {
    "order-list__item--cancelled": props.status === "cancelled",
    "order-list__item--taken": props.status === "taken",
    "order-list__item--pending": props.status === "pending",
    "order-list__item--available": props.status === "available"
  });

  return (
    <tr onClick={props.onClick} className={OrderListItemClass}>
      <td>{props.id}</td>
      <td>{props.submissionTime}</td>
      <td>{props.serviceTime}</td>
      <td>{props.location}</td>
      <td>{props.serviceType}</td>
      <td>{props.status}</td>
      <td>{props.assignee}</td>
    </tr>
  );
};

export default OrderListItem;
