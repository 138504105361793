// mock order list data:
export const getOrderList = [
  {
    id: 1,
    submissionTime: "start_time 1",
    serviceTime: "end_time 1",
    location: "location 1",
    serviceType: "Emergency Service Call",
    status: "pending",
    assignee: "name 1"
  },
  {
    id: 2,
    submissionTime: "start_time 2",
    serviceTime: "end_time 2",
    location: "location 2",
    serviceType: "Service Call by Appointment",
    status: "available",
    assignee: "TBD"
  },
  {
    id: 3,
    submissionTime: "start_time 3",
    serviceTime: "end_time 3",
    location: "location 3",
    serviceType: "Quote Request",
    status: "taken",
    assignee: "name 3"
  },
  {
    id: 4,
    submissionTime: "start_time 4",
    serviceTime: "end_time 4",
    location: "location 4",
    serviceType: "Emergency Service Call",
    status: "pending",
    assignee: "name 4"
  },
  {
    id: 5,
    submissionTime: "start_time 5",
    serviceTime: "end_time 5",
    location: "location 5",
    serviceType: "Annual Inspection",
    status: "cancelled",
    assignee: "name 5"
  },
  {
    id: 6,
    submissionTime: "start_time 6",
    serviceTime: "end_time 6",
    location: "location 6",
    serviceType: "Quarterly Inspection",
    status: "available",
    assignee: "TBD"
  },
  {
    id: 7,
    submissionTime: "start_time 7",
    serviceTime: "end_time 7",
    location: "location 7",
    serviceType: "Monthly Inspection",
    status: "taken",
    assignee: "name 7"
  },
  {
    id: 8,
    submissionTime: "start_time 8",
    serviceTime: "end_time 8",
    location: "location 8",
    serviceType: "Monthly Inspection",
    status: "cancelled",
    assignee: "name 8"
  },
  {
    id: 9,
    submissionTime: "start_time 9",
    serviceTime: "end_time 9",
    location: "location 9",
    serviceType: "Annual Inspection",
    status: "available",
    assignee: "TBD"
  },
  {
    id: 10,
    submissionTime: "start_time 10",
    serviceTime: "end_time 10",
    location: "location 10",
    serviceType: "Quote Request",
    status: "taken",
    assignee: "name 10"
  },
  {
    id: 11,
    submissionTime: "start_time 11",
    serviceTime: "end_time 11",
    location: "location 11",
    serviceType: "Quote Request",
    status: "taken",
    assignee: "name 11"
  },
  {
    id: 12,
    submissionTime: "start_time 12",
    serviceTime: "end_time 12",
    location: "location 12",
    serviceType: "Emergency Service Call",
    status: "pending",
    assignee: "name 12"
  },
  {
    id: 13,
    submissionTime: "start_time 13",
    serviceTime: "end_time 13",
    location: "location 13",
    serviceType: "Quarterly Inspection",
    status: "available",
    assignee: "TBD"
  }
];
