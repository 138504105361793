import React from "react";
import OrderListItem from "./OrderListItem";
import "./OrderListItem.scss";

const OrderList = props => {
  const OrderListItems = props.orders.map(order => {
    return (
      <OrderListItem
        key={order.id}
        id={order.id}
        submissionTime={order.submissionTime}
        serviceTime={order.serviceTime}
        location={order.location}
        serviceType={order.serviceType}
        status={order.status}
        assignee={order.assignee}
        onClick={() => props.onClick(order.id)}
      />
    );
  });

  return (
    <table>
      <thead>
        <tr>
          <th>Order ID</th>
          <th>Submission Time</th>
          <th>Service Time</th>
          <th>Location</th>
          <th>Service Type</th>
          <th>Status</th>
          <th>Assignee</th>
        </tr>
      </thead>
      <tbody>{OrderListItems}</tbody>
    </table>
  );
};

export default OrderList;
