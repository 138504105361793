import React, { useState } from "react";
import { Switch, Route, Link } from "react-router-dom";

import "./App.scss";
import OrderList from "./OrderList";
import { getOrderList } from "../mockData";

/********************************* ORDER LIST SECTION **********************************/

const handleOrderClick = (id) => {
  console.log("clicked this:", id);
};

/***************************************************************************************/

const App = () => {
  const [userInfo, setUserInfo] = useState({ id: null, type: null, email: null });
  const [loggedIn, setLoggedIn] = useState(false);

  const toggleLogInStatus = () => {
    setLoggedIn(!loggedIn);
  };

  const btn = loggedIn ? (
    <button type="button" className="btn btn-primary">
      LOGGED IN
    </button>
  ) : (
    <button type="button" className="btn btn-secondary">
      LOGGED OUT
    </button>
  );
  return (
    <>
      <h1>hotfix</h1>
      <button onClick={toggleLogInStatus} type="button" className="btn btn-danger">
        toggle status
      </button>
      <div>{btn}</div>
      <main className="layout">
        <OrderList orders={getOrderList} onClick={handleOrderClick} />
      </main>
    </>
  );
};

export default App;
